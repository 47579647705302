<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="questionModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="9">
                <CInput
                  label="Pregunta:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="3">
                <CSelect
                    label="Tipo"
                    id="type"
                    name="type"
                    :value.sync="$v.form.type.$model"
                    :options="typeOptions"
                  />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="9">
                <CInput
                  label="Descripción:"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  placeholder=""
                />
              </CCol>
              <CCol md="3">
                <CSelect
                    label="Requerido"
                    id="required"
                    name="required"
                    :value.sync="$v.form.required.$model"
                    :options="[{label: 'SI', value: '1'}, {label: 'NO', value: '0'}]"
                  />
              </CCol>
            </CRow>
            <CRow v-show="form.type == 'options'">
              <CCol md="12">
                <CInput
                  label="Opciones (separadas por comas):"
                  :lazy="false"
                  :value.sync="$v.form.options.$model"
                  placeholder=""
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"

export default {
  name: 'QuestionModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      questionModal: false,
      title: "Nueva Pregunta",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Pregunta'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      typeOptions: [{label: "Texto", value: "text"}, {label: "Opciones", value: "options"}, {label: "Fecha", value: "date"}, {label: "Archivo", value: "file"}],
      special: 0,
    }
  },
  mounted: async function() {
      
  },
  methods: {
    storeModal (special) {
      this.questionModal = true;
      this.color = "info";
      this.title = special == 1 ? 'Pregunta Dinámica' : 'Pregunta Genérica';
      this.isEdit = false;
      this.submitted = false;
      this.special = special;

      this.form = {
          id: 0,
          name: '',
          type: 'text',
          description: '',
          options: '',
          required: '1',
          special: this.special
      }
    },
    updateModal (special, item) {
      this.questionModal = true;
      this.color = "warning";
      this.title = 'Editar Pregunta';
      this.isEdit = true;
      this.submitted = false;

      this.special = special;

      this.form = {
          id: item.id,
          name: item.question,
          type: item.type,
          description: item.description,
          options: item.options,
          required: item.is_required+"",
          special: item.is_special
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.questionModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          type: 'text',
          description: '',
          options: '',
          required: '0',
          special: this.special
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      type: { },
      description: { },
      options: { },
      required: { }
    }
  },
}
</script> 