import axios from 'axios'

export default {
    get: () => axios.get('questions'),
    getSpecial: () => axios.get('questions/special'),
    getAvalilables: (travel_id) => axios.get('questions/'+travel_id+'/availables'),
    getSpecialAvalilables: (travel_id) => axios.get('questions/special/'+travel_id+'/availables'),
    getQuestion: (id) => axios.get('questions/'+id+'/show'),
    store: (data) => axios.post('questions/store', data),
    update: (data) => axios.post('questions/update', data),
    delete: (id) => axios.post('questions/delete', {id: id})
}