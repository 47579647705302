<template>
  <div>
    <CRow>
      <CCol sm="12">
        <QuestionsTable
          :items="questions"
          special="0"
          hover
          striped
          border
          small
          fixed
          caption="Preguntas Genéricas"
          @refresh="refreshItems"
          @storeModal="storeModal"
          @updateModal="updateModal"
          @deleteModal="deleteModal"
          :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
        />

        <QuestionsTable
          :items="special_questions"
          special="1"
          hover
          striped
          border
          small
          fixed
          caption="Preguntas Dinámicas"
          @refresh="refreshItems"
          @storeModal="storeModal"
          @updateModal="updateModal"
          @deleteModal="deleteModal"
          :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
        />
      </CCol>
    </CRow>

    <QuestionModal ref="questionModal" @store="storeProcess" @update="updateProcess"></QuestionModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </div>
</template>

<script>
import QuestionsTable from '../../components/questions/QuestionsTable.vue'
import QuestionModal from '../../components/questions/QuestionModal.vue'
import DeleteModal from '../../components/global/DeleteModal.vue'

import ws from '../../services/questions';
import store from '../../store'

export default {
  name: 'Questions',
  components: { QuestionsTable, QuestionModal, DeleteModal },
  data: function () {
		return {
            special: 0,
            questions: [],
            special_questions: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get();

    if(response.type == "success"){
      this.questions = response.data;
    }

    let response2 = await ws.getSpecial();

    if(response2.type == "success"){
      this.special_questions = response2.data;
    }

    this.loaded();
  },
  methods: {
    storeModal (special) {
      this.$refs.questionModal.storeModal(special);
    },
    updateModal (special, item) {
      this.$refs.questionModal.updateModal(special, item);
    },
    deleteModal (special, item) {
      this.$refs.deleteModal.show("Eliminar Pregunta", item.id, item.name);

      this.special = item.is_special+"";
    },
    async refreshItems (items){
      this.loading();
      this.questions = await items;
      this.loaded();
    },
    async refreshSpecialItems (items){
      this.loading();
      this.special_questions = await items;
      this.loaded();
    },
    async storeProcess(data){
      this.loading();
      let response = await ws.store(data);

      if(response.type == "success"){
        if(data.special+"" == "1"){
          this.special_questions = response.data;
        }
        else{
          this.questions = response.data;
        }
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    async updateProcess(data){
      this.loading();
      let response = await ws.update(data);

      if(response.type == "success"){
        if(data.special+"" == "1"){
          this.special_questions = response.data;
        }
        else{
          this.questions = response.data;
        }
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    async deleteProcess(data){
      this.loading();
      let response = await ws.delete(data);

      if(response.type == "success"){
        if(this.special+"" == "1"){
          this.special_questions = response.data;
        }
        else{
          this.questions = response.data;
        }
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
