<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nueva Pregunta</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="10"
        :dark="dark"
        pagination
      >
        <template #type="{item}"><td class="text-center w100">{{item.type == 'text' ? 'Texto' : (item.type == 'options' ? 'Opciones' : (item.type == 'date' ? 'Fecha' : (item.type == 'file' ? 'Archivo' : (item.type == 'number' ? 'Numérico' : 'N/A'))))}}</td></template>
        <template #question="{item}"><td class="text-left"><strong>{{item.question}} <span style="color: red;" v-if="item.is_required">*</span></strong><br />{{item.description}}</td></template>
        <template #options="{item}"><td class="text-left w400" style="vertical-align: middle;"><span v-if="item.optionsLabels"><ul style="margin: 0px;"><li v-for="option in item.optionsLabels">{{option}}</li></ul></span></td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import ws from '../../services/questions';
import store from '../../store'

export default {
  name: 'QuestionsTable',
  components: { },
  props: {
    special: 0,
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'type', label: 'Tipo'},
          {key: 'question', label: 'Pregunta'},
          {key: 'options', label: 'Opciones'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    storeModal () {
      this.$emit("storeModal", this.special);
    },
    updateModal (item) {
      this.$emit("updateModal", this.special, item);
    },
    deleteModal (item) {
      this.$emit("deleteModal", this.special, item);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showPermissionName(permission_code){
      let perm = this.$findElementByCode(this.permissions, permission_code);
      return perm ? perm.name : '';
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
